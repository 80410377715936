<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="ADD-ONS"
          :breadcrumb="[
            {
              label: 'Add-On',
            },
            { label: 'Add-On List' },
          ]"
        >
        </banner>
        <!-- v-if="loading == true" -->

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card "
              
            >
              <div class="card-body">
                <div v-if="loading == true">
                  <Spinner />
                </div>

                <!-- <Spinner /> -->
                <!-- start list area  -->
                <div class="row">
                  <div class="col-6 text-start">
                    <h4><b style="color: #00364f; font-weight: bolder">Add-Ons</b></h4>
                  </div>
                  <div class="col-6 text-end">
                    <button
                      style="background-color: rgb(242 19 1); padding: 5px 5px 5px 5px"
                      type="button"
                      class="btn btn-sm text-white"
                      data-bs-toggle="modal"
                      data-bs-target="#addon"
                    >
                      <b>New Add-On</b>
                    </button>
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                    style="margin-top: 7px"
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>S.No</th>
                          <th style="width: 35%">Add-On</th>
                          <th>Description</th>

                          <th class="text-center">Status</th>
                          <th>Action</th>
                        </tr>

                        <tbody>
                          <tr
                            class="text-center"
                            style="color: #00364f"
                            v-for="(mod, index) in modules"
                            :key="index"
                          >
                            <td class="text-start">{{ index + 1 }}</td>
                            <td class="text-start">{{ mod.title }}</td>
                            <td class="text-start">{{ mod.remark }}</td>
                            <td>
                              <p
                                v-if="mod.status == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                            <td>
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#addon"
                                  class="btn btns btn-sm btn-success"
                                  @click="editModule(mod)"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <!-- end list area -->

                <div class="card-footer">
                  <div class="row">
                    <div class="col-7">
                      <ul class="pagination">
                        <li class="page-item" @click="getModule(null, 'first')">
                          <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                          </a>
                        </li>
                        <li class="page-item" @click="getModule(null, 'prev')">
                          <a class="page-link" href="#">Previous</a>
                        </li>
                        <li
                          class="page-item"
                          v-if="pagination.current_page > 3"
                          @click="getModule(pagination.current_page - 3)"
                        >
                          <a class="page-link" href="#">{{
                            pagination.current_page - 3
                          }}</a>
                        </li>
                        <li
                          class="page-item"
                          v-if="pagination.current_page > 2"
                          @click="getModule(pagination.current_page - 2)"
                        >
                          <a class="page-link" href="#">{{
                            pagination.current_page - 2
                          }}</a>
                        </li>
                        <li
                          class="page-item"
                          v-if="pagination.current_page > 1"
                          @click="getModule(pagination.current_page - 1)"
                        >
                          <a class="page-link" href="#">{{
                            pagination.current_page - 1
                          }}</a>
                        </li>
                        <li
                          class="active page-item"
                          @click="getModule(pagination.current_page)"
                        >
                          <a class="page-link" href="#">{{ pagination.current_page }}</a>
                        </li>
                        <li
                          class="page-item"
                          v-if="pagination.current_page < pagination.last_page - 4"
                          @click="getModule(pagination.current_page + 1)"
                        >
                          <a class="page-link" href="#">{{
                            pagination.current_page + 1
                          }}</a>
                        </li>
                        <li
                          class="page-item"
                          v-if="pagination.current_page < pagination.last_page - 5"
                          @click="getModule(pagination.current_page + 2)"
                        >
                          <a class="page-link" href="#">{{
                            pagination.current_page + 2
                          }}</a>
                        </li>
                        <li
                          class="page-item"
                          v-if="pagination.current_page < pagination.last_page - 6"
                          @click="getModule(pagination.current_page + 3)"
                        >
                          <a class="page-link" href="#">{{
                            pagination.current_page + 3
                          }}</a>
                        </li>
                        <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="getModule( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                        <li class="page-item">
                          <a class="page-link" href="#">...</a>
                        </li>

                        <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="getModule( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                        <li
                          class="page-item"
                          v-if="pagination.current_page < pagination.last_page - 3"
                          @click="getModule(pagination.last_page - 3)"
                        >
                          <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                        </li>
                        <li
                          class="page-item"
                          v-if="pagination.current_page < pagination.last_page - 2"
                          @click="getModule(pagination.last_page - 2)"
                        >
                          <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                        </li>
                        <li
                          class="page-item"
                          v-if="pagination.current_page < pagination.last_page - 1"
                          @click="getModule(pagination.last_page - 1)"
                        >
                          <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                        </li>

                        <li class="page-item" @click="getModule(null, 'next')">
                          <a class="page-link" href="#">Next</a>
                        </li>
                        <li class="page-item" @click="getModule(null, 'last')">
                          <a class="page-link" href="#">&raquo;</a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-4 text-end">
                      Showing {{ pagination.from }} to {{ pagination.to }} of
                      {{ pagination.total }} entries
                    </div>
                    <div
                      class="col-1 text-end"
                      style="height: 30px; padding: 5px 5px 5px 5px"
                    >
                      <select
                        style="height: 30px; padding: 5px 5px 5px 5px"
                        class="form-select"
                        @change="changePageNo"
                        aria-label="Default select example"
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!--start model  -->
  <div
    class="modal font fade"
    id="addon"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content" style="border-radius: 1.4vw">
        <div class="modal-header bg-white" style="border-radius: 1.4vw">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f; font-size: 1.4vw"
          >
            <b> {{ edit == "false" ? "Add" : "Edit" }} Add-ons</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="margin-top: -10px">
          <form @submit.prevent="edit == 'false' ? addNewModule() : updateModule()">
            <div class="form-group">
              <lable><strong class="text">Name</strong></lable>
              <input
                type="text"
                class="form-control"
                placeholder="Name of Addon"
                v-model="form.title"
                required
              />
            </div>
            <div class="form-group" style="margin-top: 5px">
              <lable><strong class="text">Status</strong></lable>
              <select
                class="form-select"
                aria-label="Default select example"
                v-model="form.status"
              >
                <option selected>Status...</option>
                <option value="true">Active</option>
                <option value="false">InActive</option>
              </select>
            </div>
            <div class="form-group" style="margin-top: 5px">
              <lable><strong class="text">Remark</strong></lable>
              <textarea
                class="form-control"
                v-model="form.remark"
                rows="4"
                placeholder="Description"
              ></textarea>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white"
                style="background-color: #00364f"
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->
</template>

//
<script>
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "Package Addons ",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 5,
      loading: false,
      packages: [],
      modules: [],
      edit: "false",
      form: new Form({
        id: null,
        title: "",
        status: "",
        remark: "",
      }),
    };
  },
  methods: {
    changePageNo(e) {
      this.per_page = e.target.value;
      this.getModule();
    },
    editModule(mod) {
      this.edit = "true";
      this.form.id = mod.id;
      this.form.title = mod.title;
      this.form.status = mod.status;
      this.form.remark = mod.remark;
    },
    updateModule() {
      this.loading = true;
      this.$axios
        .put(`superadmin/memberaddons/${this.form.id}`, this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data);
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Addon Updated  Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });

          this.getModule().finally(() => (this.loading = false));
        })
        .catch((error) => {
          error = error.data.message;
          console.log(error);
        });
    },
    addNewModule() {
      this.loading = true;
      this.$axios
        .post("superadmin/memberaddons", this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res.data.data;
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Addon  Created  Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });
          this.form = {};
          this.form.errors = {};
          this.getModule().finally(() => (this.loading = false));
        })
        .catch((error) => {
          // error = error.data.message
          console.log(error);
        });
    },
    getModule(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        pageUrl += `superadmin/memberaddons?per_page=${this.per_page}`;
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.modules = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.addons
    console.log(this.$store.state.superAdminPageTitles.addons)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.getModule();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.spinnning-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
</style>
